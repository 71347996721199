<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI说明书" />
                        <ai_select selectTitle="产品品类" :select_list="style_list" @getSelectVal="get_style" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">产品名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.product_name" placeholder="请录入具体产品名。如：手机、空调遥控器、洗发露等"></el-input>
                            </div>
                        </div>
                        <!-- <div class="form_ct flex">
                            <p class="upLoad_text">关键词</p>
                            <el-input v-model="form.keyword" placeholder="如:有趣的建筑"></el-input>
                        </div> -->
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title'
import ai_select from '@/components/ai_select'
import ai_text_content from '@/components/ai_text_content'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() { this.getUserInfo() },
    components: {
        wordNav,
        ai_title,
        ai_select,
        ai_button_file_data,
        ai_text_content
    },
    data() {
        return {
            form: {
                type: '',
                product_style: '',
                product_name: '',
            },
            style_list: [
                {
                    id: '消费品',
                    name: '消费品'
                },
                {
                    id: '工业品',
                    name: '工业品'
                },
                {
                    id: '半成品',
                    name: '半成品'
                },
                {
                    id: '服务产品',
                    name: '服务产品'
                },
                {
                    id: '数字产品',
                    name: '数字产品'
                },
                {
                    id: '文化产品',
                    name: '文化产品'
                },
                {
                    id: '生活服务产品',
                    name: '生活服务产品'
                },
                {
                    id: '环保产品',
                    name: '环保产品'
                },

            ],
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_style(val) {
            this.form.product_style = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/create_instructions', {
                product_style: this.form.product_style,
                product_name: this.form.product_name,
                mobile: this.$user_info.mobile,
            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>